import zhLocale from 'element-ui/lib/locale/lang/en'
export default {
  "lang": "中文",
  // 菜单
  "menu": {
    "media": "媒体",//媒体
    "data": "数据",//数据
    "docking": "对接",//对接
    "operator": "当前操作账号：",//当前操作账号
  },
  "index": {
    "search": "搜索"
  },
  ...zhLocale
}