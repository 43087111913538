const getters = {
  showLogin: state => state.user.showLogin,
  dateRange: state => state.hotel.dateRange,
  peopleNum: state => state.hotel.peopleNum,
  roomNum: state => state.hotel.roomNum,
  user:state=>state.user.user,
  username: state=>state.user.username,
  password: state=>state.user.password,
  loading:state=>state.user.loading
}
export default getters