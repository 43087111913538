import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/index.scss'
import '@/style/elementui-variables.scss' // 主题文件
import i18n from '@/i18n/index'
import BasePagination from '@/components/BasePagination/index'

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'c38a4430a7f157c4df4691831e4c8530',
  //plugin: ['AMap.Autocomplete'],
  v: '1.4.4',
  uiVersion: '1.0.11'
})

Vue.use(ElementUI)
Vue.component("BasePagination", BasePagination)
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
