import { userLogin, userLogout } from '@/api/login';
import { removeToken, setToken  } from '@/utils/auth';
import { Message } from 'element-ui'
import router from '../../router/index'
const state = {
  showLogin: false,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '',
  username: localStorage.getItem('username'),
  password: localStorage.getItem('password'),
  loading: false
}
const mutations = {
  SET_SHOWLOGIN: (state, showLogin)=>{
    state.showLogin = showLogin
  },
  SET_USERINFO: (state,user) => {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  SET_USERNAME:(state,{username,password})=>{
    state.username = username
    state.password = password 
  },
  SET_LOADING:(state,loading) => {
    state.loading = loading
  }
}
const actions = {
  login:({commit},{userInfo,rememberPassword})=>{
    commit('SET_SHOWLOGIN',true)
    userLogin(userInfo).then(res => {
      if(res.status==2000) {
        setToken(res.data.token)
        commit('SET_USERINFO', res.data)
        commit('SET_SHOWLOGIN',false)
        commit('SET_LOADING', false)
        if(rememberPassword) {
          commit('SET_USERNAME', {username:userInfo.username,password:userInfo.password})
          localStorage.setItem('username',userInfo.username)
          localStorage.setItem('password',userInfo.password)
        } else{
          commit('SET_USERNAME', {username:'',password:''})
          localStorage.setItem('username','')
          localStorage.setItem('password','')
        }
        Message({
          message: res.message,
          type: 'success',
          duration: 5 * 1000,
          offset: 120
        })
      }else{
        Message({
          message: res.message,
          type: 'error',
          duration: 5 * 1000,
          offset: 120
        }) 
      } 
   })
  },
  logout: ({ commit }) => {
    userLogout({}).then(res => {
      if(res.status==2000) {
        commit('SET_USERINFO', '')
        removeToken()
        Message({
          type: 'success',
          message: '已退出登录!'
        })
        if(router.currentRoute.name!='IndexIndex'){
          router.push({path:'/'})
        }
      }
    })
  },
}
export default{
  namespaced: true,
  state,
  mutations,
  actions
}