import request from './request'
export const registerCaptcha = (data) => {
  return request('api/register/captcha', 'post', data)
}
export const register = (data) => {
  return request('api/register', 'post', data)
}
export const userLogin = (data) => {
  return request('api/login', 'post', data)
}
export const userLogout = (data) => {
  return request('api/logout', 'post', data)
}
export const passwordResetCaptcha = (data) => {
  return request('api/password/reset/captcha', 'post', data)
}
export const passwordReset = (data) => {
  return request('api/password/reset', 'post', data)
}