import enLocale from 'element-ui/lib/locale/lang/en'
export default {
  "lang": "English",
  // 菜单
  "menu": {
    "media": "media",//媒体
    "data": "data",//数据
    "docking": "docking",//对接
  },
  "index": {
    "search": "search"
  },
  ...enLocale
}