import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LayoutIndex',
    redirect: '/index',
    component: () => import('@/views/layout/index'),
    children: [
      {
        path: 'index',
        name: 'IndexIndex',
        component: () => import('@/views/index/index')
      },
      {
        path: 'register',
        name: 'UserRegister',
        component: () => import('@/views/login/register')
      },
      {
        path: 'password',
        name: 'PasswordReset',
        component: () => import('@/views/login/PasswordReset')
      },
      {
        path: 'search',
        name: 'IndexSearch',
        component: () => import('@/views/index/search')
      },
      {
        path: 'hotelConfirm',
        name: 'HotelConfirm',
        component: () => import('@/views/hotel/confirm')
      },
      {
        path: 'admin',
        component:() => import('@/views/admin/index'),
        children: [
          {
            path: 'hotelDetail',
            name: 'HotelDetail',
            component: () => import('@/views/hotel/detail')
          },
          {
            path: 'user',
            name: 'UserIndex',
            component: () => import('@/views/user/index')
          },
          {
            path: 'account',
            name: 'UserAccount',
            component: () => import('@/views/user/account')
          },
          {
            path: 'advert',
            name: 'AdvertIndex',
            component: () => import('@/views/advert/index'),
            meta: {isAdmin: true}
          },
          {
            path: 'order',
            name: 'OrderIndex',
            component: () => import('@/views/order/index')
          },
          {
            path: 'agent',
            name: 'AgentIndex',
            component: () => import('@/views/agent/index')
          },
          {
            path: 'agentEdit',
            name: 'AgentEdit',
            component: () => import('@/views/agent/edit'),
            meta: {activeMenu: '/admin/agent'}
          },
          {
            path: 'commissionStatistics',
            name: 'ComissionStatistics',
            component: () => import('@/views/commission/statistics')
          },
          {
            path: 'commissionOrder',
            name: 'ComissionOrder',
            component: () => import('@/views/commission/order')
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if(to.meta.isAdmin) {
    if(store.state.user.user.type==99) {
      next()
    } else{
      next(false)
    }
  }else{
    next()
  }
  
})

export default router
