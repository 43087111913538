import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 1000 * 60 * 10
})

// request interceptor
service.interceptors.request.use(
    config => {
        // if (store.getters.token) {
          config.headers['x-utth-token'] = getToken() || ''
       // }
        return config
    },
    error => {
        // do something with request error
        console.log('error', error) // for debug
        return Promise.reject(error)
    }
)

  // response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (Number(res.status) !== 2000) {
            if (res.status == 5007 || res.status == 5008) { //token失效 重新登录
              store.commit('user/SET_SHOWLOGIN', true)
              store.commit('user/SET_USERINFO', '')
              Message({
                message: '请登录',
                type: 'error',
                duration: 5 * 1000,
                offset: 120
              }) 
            } else{
              Message({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000,
                offset: 120
              }) 
            }
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

// export default service
export default function request(url, method, data) {
    method = method.toLocaleLowerCase()
    for (const key in data) {
        if (data[key] === undefined || data[key] === null) {
            delete data[key]
        }
    }
    return service({
        url: url,
        method: method,
        data
    })
}
