import moment from 'moment'
const state = {
  dateRange: [moment().format('yyyy-MM-DD'),moment().add(1,'days').format('yyyy-MM-DD')],
  peopleNum: 2,
  roomNum:1
}
const mutations = {
  SET_DATERANGE:(state, dateRange)=>{
    state.dateRange = dateRange
  },
  SET_PEOPLENUM:(state, peopleNum) =>{
    state.peopleNum = peopleNum
  },
  SET_ROOMNUM:(state, value) =>{
    state.roomNum = value
  }
}
const actions = {

}
export default{
  namespaced: true,
  state,
  mutations,
  actions
}